import { SetState, SortState } from 'src/common/types'
import { SortValue } from '../types'
import React from 'react'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Grow from '@material-ui/core/Grow'

type SortValuesProps = {
  sorting: SortState<SortValue>
  setSorting: SetState<SortState<SortValue>>
}
const SortValues = ({ sorting, setSorting }: SortValuesProps) => {
  const handleClick = (value: SortValue) => () => {
    if (sorting.value === value) {
      setSorting((sorting) => ({ value, isDesc: !sorting.isDesc }))
      return
    }
    setSorting({ value, isDesc: false })
  }

  const sortComponents = sortValues.map((value) => {
    const isValue = value === sorting.value
    return (
      <Value
        key={value}
        onClick={handleClick(value)}
        style={{
          fontWeight: isValue ? 'inherit' : 'normal',
          opacity: isValue ? 1 : 0.85,
          color: isValue ? '#fff' : 'inherit',
        }}
      >
        {value}
        <Grow in={isValue} timeout={150}>
          <IconWrapper>
            <Icon
              style={{
                transform: sorting.isDesc ? 'rotateX(180deg)' : 'rotateX(0deg)',
                // color: isValue ? '#fff' : 'inherit',
              }}
              // fontSize="inherit"
            />
          </IconWrapper>
        </Grow>
      </Value>
    )
  })
  return <SortContainer variant="outlined">{sortComponents}</SortContainer>
}

export default SortValues

export const sortValues = ['name', 'age', 'number', 'height', 'weight'] as const

const SortContainer = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: space-around;
  line-height: ${(p) => `${40 - p.theme.spacing(1) * 2}px`};
  padding: ${(p) => p.theme.spacing(1)}px;
  flex-grow: 1;
  width: 100%;
  height: fit-content;
  user-select: none;
  background-color: transparent;
  color: ${(p) => p.theme.palette.primary.light};
  font-size: 0.9em;
  ${(p) => p.theme.breakpoints.up('sm')} {
    justify-content: space-around;
    font-size: 1.1em;
  }
`
const Value = styled.div`
  position: relative;
  display: inline-flex;
  cursor: pointer;
`
const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${(p) => `${40 - p.theme.spacing(1) * 2}px`};
`
const Icon = styled(ExpandLessIcon)`
  font-size: 1.2em;
  transition: ${(p) =>
    p.theme.transitions.create(['transform', 'color'], {
      duration: p.theme.transitions.duration.shortest,
    })};
`
