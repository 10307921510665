import { Player, SetState, SortState } from 'src/common/types'
import { SortValue, SelectedFilter } from '../types'
import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import PlayerFilter from './player-filter'
import PositionFilter from './position-filter'
import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'
import { glassyDefaultBG } from 'src/common/styles'
import SortValues from './sort-values'

export type RosterSortFilterProps = {
  sorting: SortState<SortValue>
  setSorting: SetState<SortState<SortValue>>
  filteredPlayers: Player[]
  setFilteredPlayers: SetState<Player[]>
  allPlayers: Player[]
}
const RosterSortFilter = ({
  sorting,
  setSorting,
  filteredPlayers,
  setFilteredPlayers,
  allPlayers,
}: RosterSortFilterProps) => {
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>('player')

  const handleFilterChange = (e: any, value: SelectedFilter | null) => {
    //value will be null when user clicks already selected filter button
    if (value !== null) {
      setSelectedFilter(value)
      setFilteredPlayers([])
    }
  }

  const filterProps = {
    filteredPlayers,
    setFilteredPlayers,
    allPlayers,
  }

  const variants = {
    player: { rotateX: '0deg' },
    position: { rotateX: '90deg' },
  }
  return (
    <Container variant="outlined">
      <FilterContainer>
        <TransformWrapper
          variants={variants}
          initial={selectedFilter}
          animate={selectedFilter}
          transition={{ duration: 0.1 }}
        >
          <Front>
            <PlayerFilter
              {...filterProps}
              isPlayerFilter={selectedFilter === 'player'}
            />
          </Front>
          <Bottom>
            <PositionFilter {...filterProps} />
          </Bottom>
        </TransformWrapper>
        <FilterButtonGroup
          size="small"
          value={selectedFilter}
          onChange={handleFilterChange}
          exclusive
        >
          <ToggleButton value="player" disableRipple>
            <PersonIcon />
          </ToggleButton>
          <ToggleButton value="position" disableRipple>
            <PeopleIcon />
          </ToggleButton>
        </FilterButtonGroup>
      </FilterContainer>

      <SortValues sorting={sorting} setSorting={setSorting} />
    </Container>
  )
}

export default RosterSortFilter

const Container = styled(Paper)`
  grid-column: 1/-1;
  display: grid;
  margin: auto;
  width: 100%;
  max-width: 800px;
  max-width: ${(p) => p.theme.breakpoints.values.md}px;
  grid-template-columns: ${(p) =>
    `repeat(auto-fit, minmax(${320 - p.theme.spacing(1) * 4}px, 1fr))`};
  padding: ${(p) => p.theme.spacing(1)}px;
  grid-gap: ${(p) => p.theme.spacing(1)}px;
  ${(p) => p.theme.breakpoints.up('sm')} {
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    grid-gap: ${(p) => p.theme.spacing(2)}px;
  }
  ${glassyDefaultBG};
`
const FilterContainer = styled.div`
  width: 100%;
  display: flex;
`
const TransformWrapper = styled(motion.div)`
  position: relative;
  width: 100%;
  transform-style: preserve-3d;
`

const Front = styled.div`
  transform: rotateX(0deg) translateZ(20px);
`
const Bottom = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 40px;
  transform: rotateX(-90deg) translateZ(20px);
`
const FilterButtonGroup = styled(ToggleButtonGroup)`
  margin-left: ${(p) => p.theme.spacing(1)}px;
  height: fit-content;
`
