import { useStaticQuery, graphql } from 'gatsby'
import { DarrenQuery } from 'types/graphql-types'

const useDarrenFluidData = () => {
  const { allFile } = useStaticQuery<DarrenQuery>(graphql`
    query Darren {
      allFile(
        filter: {
          relativePath: { eq: "darren/Darren.png" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 657, quality: 75) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  return allFile.nodes[0].childImageSharp?.fluid
}

export default useDarrenFluidData
