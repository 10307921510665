import { Player } from 'src/common/hooks/use-roster-data'
import React, { useState, useEffect, useContext } from 'react'
// import isTouchDevice from 'is-touch-device'
import { motion, useAnimation } from 'framer-motion'
import { navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import color from 'color'
import Img from 'gatsby-image'
import Avatar from '@material-ui/core/Avatar'
import Paper from '@material-ui/core/Paper'
// import Zoom from '@material-ui/core/Zoom'
import Typography from '@material-ui/core/Typography'
import useHeadshots from 'src/common/hooks/use-headshots'
// import useTheme from 'src/common/hooks/use-theme'
import { SettingsContext } from '../settings/settings-context'
import { convertHeightToMeters, convertWeightToKg } from './utils'

type PlayerCardProps = {
  player: Player
  i: number
}
const PlayerCard = ({ player, i }: PlayerCardProps) => {
  // const controls = useAnimation()
  const [{ isEuroMode }] = useContext(SettingsContext)
  // const theme = useTheme()
  // const widthWithTwoColumns = 863
  // const shouldStaggerIn =
  //   typeof window !== undefined && window.innerWidth > widthWithTwoColumns
  // const [isIn, setIsIn] = useState(!shouldStaggerIn)
  const headshots = useHeadshots()
  // const isTouch = isTouchDevice()

  const {
    age,
    designations,
    height,
    hometown,
    name,
    number,
    position,
    weight,
  } = player

  const mHeight = convertHeightToMeters(height)
  const localeHeight = isEuroMode
    ? mHeight?.toString().replace('.', ',')
    : height.replace(' ', '')

  const kgWeight = convertWeightToKg(weight)
  const localeWeight = isEuroMode ? kgWeight.toString() : weight

  // useEffect(() => {
  //   controls.start({
  //     scale: 1,
  //     transition: { delay: i * 0.02, type: 'spring' },
  //   })
  // }, [])

  // //stagger 'in' transition on mount
  // useEffect(() => {
  //   const handler = () => {
  //     setIsIn(true)
  //   }
  //   const timeout = setTimeout(handler, i * 20)

  //   return () => clearTimeout(timeout)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const handleJosefClick = () => {
    navigate('/valentine')
  }

  return (
    // <Zoom in={isIn} appear={shouldStaggerIn}>
    <motion.li
    // initial={{ scale: 0 }}
    // animate={{ scale: 1 }}
    // animate={controls}
    // custom={i}
    // whileTap={
    //   isTouch
    //     ? undefined
    //     : { zIndex: 9999999, backgroundColor: theme.palette.background.paper }
    // }
    // transition={{ duration: 0.3, ease: 'linear' }}
    // layoutTransition
    // positionTransition
    // drag={!isTouch}
    // dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
    // dragElastic={1}
    >
      <Card variant="elevation">
        {true ? (
          <HeadshotContainer
            onClick={name === 'Josef Martinez' ? handleJosefClick : undefined}
            style={name === 'Josef Martinez' ? { cursor: 'help' } : undefined}
            className="headshot-container"
          >
            {headshots[name] ? (
              <Headshot fluid={headshots[name] as any} draggable={false} />
            ) : (
              <PlaceholderHeadshot />
            )}
          </HeadshotContainer>
        ) : (
          <div />
        )}

        <div>
          <FlexLine>
            <Name variant="h5">{name}</Name>
            <Number variant="h5" component="div">
              {number}
            </Number>
          </FlexLine>

          <Position component="div">{position}</Position>
          <Hometown variant="body2" component="div">
            {hometown}
          </Hometown>

          <AgeWeightHeight>
            <div>
              {age && (
                <>
                  <span>Age</span>{' '}
                  <AgeWeightHeightNum>{age}</AgeWeightHeightNum>
                </>
              )}
            </div>
            <div>
              {height && (
                <>
                  <span>HT</span>{' '}
                  <AgeWeightHeightNum>{localeHeight}</AgeWeightHeightNum>
                </>
              )}
            </div>
            <div>
              {weight && (
                <>
                  <span>WT</span>{' '}
                  <AgeWeightHeightNum>{localeWeight}</AgeWeightHeightNum>
                </>
              )}
            </div>
          </AgeWeightHeight>
          <DesignationContainer>
            {designations.map((designation) => (
              <Designation key={designation} component="span">
                {designation}
              </Designation>
            ))}
          </DesignationContainer>
        </div>
      </Card>
    </motion.li>
    // </Zoom>
  )
}

export default React.memo(PlayerCard)

type HasComponentProp = {
  component?: string
}
const Card = styled(Paper)`
  display: grid;
  grid-template-columns: 100px auto;
  grid-gap: ${(p) => p.theme.spacing(1)}px;
  height: 100%;
  margin-bottom: 0;
  padding: ${(p) => p.theme.spacing(2)}px;
  background-color: ${(p) => p.theme.palette.background.paper}cc;
  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(2)}px;
  }
`
const HeadshotContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  border-radius: 50%;
  border: 1px solid #d3d3cf;
  box-shadow: 0 0 5px rgba(206, 156, 1, 1), inset 0 0 5px rgba(206, 156, 1, 1);
  background-color: ${(p) =>
    color(p.theme.palette.primary.dark)
      .fade(0.9)
      .toString()};
`
const dropShadow = css`
  filter: drop-shadow(0px 0px 2.5px rgba(206, 156, 1, 0.5));
`
const Headshot = styled(Img)`
  /* ${dropShadow} */
`
const PlaceholderHeadshot = styled(Avatar)`
  width: 165%;
  height: 165%;
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: rgba(0, 0, 0, 0);
  color: ${(p) => p.theme.palette.primary.light};
  /* ${dropShadow} */
`
const Name = styled(Typography)`
  /* grid-column: 1 / -2; */
`
const Number = styled(Typography)<HasComponentProp>`
  margin-left: ${(p) => p.theme.spacing(1)}px;
`
const FlexLine = styled.div`
  display: flex;
  justify-content: space-between;
`
const Position = styled(Typography)<HasComponentProp>`
  opacity: 0.85;
`
const Hometown = styled(Typography)<HasComponentProp>`
  opacity: 0.75;
`
//Grid works better than flex here in case of empty values
const AgeWeightHeight = styled.div`
  display: grid;
  /* max-width: 350px; */
  grid-template-columns: 1fr 1fr 1fr;
  > div:nth-child(2) {
    text-align: center;
  }
  > div:last-child {
    text-align: right;
  }
`
const AgeWeightHeightNum = styled.span`
  font-weight: normal;
`
const DesignationContainer = styled(FlexLine)`
  flex-wrap: wrap;
  /* max-width: 350px; */
`
const Designation = styled(Typography)<HasComponentProp>`
  background-color: rgba(255, 255, 255, 0.2);
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.85em;
  line-height: 1.5em;
  padding: 0 0.2em;
  letter-spacing: 0.25ch;
  margin-top: 0.4ch;
`
