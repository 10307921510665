import { SortValue } from 'src/features/roster/types'
import { Player, SortState } from 'src/common/types'
import React, { useEffect, useContext, useState } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { PageProps } from 'gatsby'
import { sortAlphabetically, sortPlayers } from 'src/features/roster/utils'
import { LayoutContext } from 'src/features/layout'
import SEO from 'src/common/seo'
import Darren from 'src/features/roster/darren'
import PlayerCard from 'src/features/roster/player-item'
import useRosterData from 'src/common/hooks/use-roster-data'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import { GridList, dodgeFab } from 'src/common/styles'
import RosterSortFilter, {
  RosterSortFilterProps,
} from 'src/features/roster/roster-sort-filter'
import ScrollToTopFab from 'src/features/scroll-to-top-fab'
import { fabSizePx } from 'src/common/components/fab-container'

const Roster = ({ location }: PageProps) => {
  const props = useMotionNavOffsetStyle()
  const rosterData = useRosterData()
  const { setLocation } = useContext(LayoutContext)
  const [filteredPlayers, setFilteredPlayers] = useState<Player[]>([])
  const [sorting, setSorting] = useState<SortState<SortValue>>({
    value: 'name',
    isDesc: false,
  })

  useEffect(() => {
    setLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const allPlayers = sortAlphabetically(
    rosterData.filter((player) => player.name !== 'Atlanta Supporters')
  )

  const sortedPlayers = sortPlayers(sorting, filteredPlayers, allPlayers)

  const filterProps: RosterSortFilterProps = {
    sorting,
    setSorting,
    filteredPlayers,
    setFilteredPlayers,
    allPlayers,
  }
  return (
    <>
      <SEO title="Roster" />
      <Darren />
      <Container {...props}>
        <PlayerGridList>
          <RosterSortFilter {...filterProps} />
          {sortedPlayers.map((player, i) => (
            <PlayerCard player={player} i={i} key={player.name} />
          ))}
        </PlayerGridList>

        <ScrollToTopFab />
      </Container>
    </>
  )
}

export default Roster

const Container = styled(motion.div)`
  ${dodgeFab({ contentWidth: 1374 - fabSizePx * 2 })}
`
const PlayerGridList = styled(GridList)`
  /* overflow: hidden; */
  max-width: 100%;
  ${(p) => p.theme.breakpoints.up('sm')} {
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    padding-left: ${(p) => p.theme.spacing(3)}px;
    padding-right: ${(p) => p.theme.spacing(3)}px;
  }
`
