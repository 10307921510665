import { Player, SetState } from 'src/common/types'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import styled from 'styled-components'
import { glassyDefaultBG } from 'src/common/styles'

type PlayerFilterProps = {
  filteredPlayers: Player[]
  setFilteredPlayers: SetState<Player[]>
  allPlayers: Player[]
  isPlayerFilter: boolean
}
const PlayerFilter = ({
  filteredPlayers,
  setFilteredPlayers,
  allPlayers,
  isPlayerFilter,
}: PlayerFilterProps) => {
  const forwards = allPlayers.filter((p) => p.position === 'Forward')
  const midfielders = allPlayers.filter((p) => p.position === 'Midfielder')
  const defenders = allPlayers.filter((p) => p.position === 'Defender')
  const keepers = allPlayers.filter((p) => p.position === 'Goalkeeper')
  const sortedPlayersForFilter = isPlayerFilter
    ? Array.from(
        new Set([
          ...forwards,
          ...midfielders,
          ...defenders,
          ...keepers,
          //just in case
          ...allPlayers,
        ])
      )
    : []

  // const filteredPlayers = allPlayers.filter((player) => {
  //   const filteredNames = filteredPlayers.map((player) => player.name)
  //   return filteredNames.includes(player.name)
  // })

  const handleAutocompleteChange = (e: React.ChangeEvent<{}>, value: any) => {
    setFilteredPlayers(value as Player[])
  }

  return (
    <Autocomplete
      value={isPlayerFilter ? filteredPlayers : []}
      onChange={handleAutocompleteChange}
      options={sortedPlayersForFilter}
      size="small"
      autoComplete
      // autoSelect
      autoHighlight
      // disableCloseOnSelect
      multiple
      openOnFocus
      getOptionLabel={(option) => option.name}
      groupBy={(option) => option.position}
      renderInput={(params) => (
        <PlayerTextField {...params} placeholder="players" variant="outlined" />
      )}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={selected}
            color="primary"
          />
          {option.name}
        </React.Fragment>
      )}
    />
  )
}

export default PlayerFilter

const PlayerTextField = styled(TextField)`
  /* ${glassyDefaultBG}; */
  /* background-color: ${(p) => p.theme.palette.background.paper}; */
`
