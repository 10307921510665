import { Player, SetState } from 'src/common/types'
import React, { useState } from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'

type PositionFilterProps = {
  filteredPlayers: Player[]
  setFilteredPlayers: SetState<Player[]>
  allPlayers: Player[]
}
const PositionFilter = ({
  filteredPlayers,
  setFilteredPlayers,
  allPlayers,
}: PositionFilterProps) => {
  const [isOpen, setIsOpen] = useState(false)
  // const [value, setValue] = useState<string[]>([])
  const positions = Array.from(
    new Set(allPlayers.map((player) => player.position))
  )
  const value = Array.from(
    new Set(filteredPlayers.map((player) => player.position))
  )
  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    const value = e.target.value as string[]
    if (value.includes('all')) {
      // setValue([])
      setFilteredPlayers([])
      setIsOpen(false)
      return
    }
    // setValue(value)
    setFilteredPlayers(
      allPlayers.filter((player) => value.includes(player.position))
    )
  }
  return (
    <StyledFormControl variant="outlined">
      <InputLabel id="filter-position-label">positions</InputLabel>
      <Select
        id="filter-position"
        label="positions"
        labelId="filter-position-label"
        value={value}
        onChange={handleChange}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        multiple
        renderValue={(selected) => (selected as string[]).join(', ')}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        }}
      >
        <MenuItem value="all">
          <Checkbox
            checked={!value.length}
            style={{ marginRight: 8 }}
            color="primary"
          />
          All Positions
        </MenuItem>
        {positions.map((position) => (
          <MenuItem key={position} value={position}>
            <Checkbox
              checked={value.includes(position)}
              style={{ marginRight: 8 }}
              color="primary"
            />
            {position}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
}

export default PositionFilter

const StyledFormControl = styled(FormControl)`
  width: 100%;

  /* background-color: ${(p) => p.theme.palette.background.paper}; */
  .MuiSelect-root {
    height: 40px;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`
